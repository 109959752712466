import React, { useCallback, useEffect, useRef, useState } from "react";
import "./site.scss";
import Form from "devextreme-react/form";
import DataGrid, {
  Column,
  DataGridTypes,
  Export,
  FilterPanel,
  HeaderFilter,
  Item,
  Scrolling,
  Search,
  SearchPanel,
  Sorting,
  Toolbar,
  LoadPanel,
  ColumnChooser,
  Selection,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { SiteNone, Site, RoleType } from "../../types/shortlink";
import { FormPopup } from "../../components/utils/form-popup/FormPopup";
import Button from "devextreme-react/button";
import { SiteForm } from "../../components/library/site-form/SiteForm";
import { exportDataGrid as exportDataGridToXLSX } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { Workbook } from "exceljs";
import { useApi } from "../../hooks/useApi";
import { SiteDelete } from "../../components/library/site-delete/SiteDelete";
import { useSiteService } from "../../hooks/useSiteService";
import ProtectedRoute from "../../components/utils/protected-route/ProtectedRoute";
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import { AxiosError } from "axios";

export default function SiteList() {
  const selectedRow = useRef<number>(0);
  const [popupUpdateVisible, setPopupUpdateVisible] = useState<boolean>(false);
  const [popupNewVisible, setPopupNewVisible] = useState<boolean>(false);
  const [popupDeleteVisible, setPopupDeleteVisible] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<Site>(SiteNone);

  const [formDataDefaults, setFormDataDefaults] = useState<Site>({
    ...SiteNone,
  });

  const gridRef = useRef<DataGrid>(null);

  const [siteService] = useSiteService();

  const newSiteData = useRef<Site>(SiteNone);
  const updateSiteData = useRef<Site>(SiteNone);
  let deleteSiteData: Site;

  const onLoad = async (loadOptions: LoadOptions<Site>) =>
    await siteService.getAll().catch((error) => {});

  const onInsert = async (category: Site) =>
    await siteService.create(category).catch((error) => {});

  const onInserted = (site: Site, key: string) => {
    notify(
      {
        message: `New site "${site.name}" saved`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const onUpdate = async (key: string, site: Site) =>
    await siteService.update(site);

  const onUpdated = (key: string, site: Site) => {
    notify(
      {
        message: `Shortlink "${site.name}" updated`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const onRemove = async (key: string) => await siteService.delete(key);

  const onRemoved = async (key: string) => {
    notify(
      {
        message: `The category "${key}" was deleted with success.`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const [gridDataSource, setGridDataSource] = useState<
    CustomStore<Site, string>
  >(
    new CustomStore({
      key: "id",
      load: onLoad,
      update: onUpdate,
      insert: onInsert,
      remove: onRemove,
      onInserted: onInserted,
      onUpdated: onUpdated,
      onRemoved: onRemoved,
    })
  );

  function checkForElement(
    selector,
    callback,
    interval = 500,
    timeout = 10000
  ) {
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const element = document.querySelector(selector);

      if (element) {
        clearInterval(intervalId);
        callback(element);
      } else if (Date.now() - startTime > timeout) {
        clearInterval(intervalId);
      }
    }, interval);
  }

  const onDataErrorOccurred = useCallback(
    (event: DataGridTypes.DataErrorOccurredEvent) => {
      let customErrorMessage: String | null = null;

      let axiosError: AxiosError = event.error as AxiosError;

      if (axiosError?.response?.data) {
        customErrorMessage =
          axiosError.response.data[0].message || customErrorMessage;
      }
      checkForElement(".dx-error-message", (element) => {
        if (customErrorMessage) element.innerHTML = customErrorMessage;
      });
    },
    []
  );

  const onDataDeleteChanged = useCallback((data: Site) => {
    deleteSiteData = data;
  }, []);

  const onDataCreateChanged = useCallback((data: Site) => {
    newSiteData.current = data;
  }, []);

  const onDataUpdateChanged = useCallback((data: Site) => {
    updateSiteData.current = data;
  }, []);

  const changeUpdatePopupVisibility = useCallback((isVisble) => {
    setPopupUpdateVisible(isVisble);
  }, []);

  const changeNewPopupVisibility = useCallback((isVisble) => {
    setPopupNewVisible(isVisble);
  }, []);

  const updateSiteClick = () => {
    setSelectedSite(selectedSite);
    setPopupUpdateVisible(true);
  };

  const changeDeletePopupVisibility = useCallback((isVisble) => {
    setPopupDeleteVisible(isVisble);
  }, []);

  const onFocusedRowChanged = useCallback(
    ({ row }: DataGridTypes.FocusedRowChangedEvent) => {
      if (!row) return;

      const siteData: Site = row.data as Site;

      setSelectedSite(siteData);
      selectedRow.current = row.rowIndex;
    },
    []
  );

  const onAddSiteClick = useCallback(() => {
    setPopupNewVisible(true);
  }, []);

  const onDeleteSiteClick = useCallback(() => {
    setPopupDeleteVisible(true);
  }, []);

  const onSaveClick = useCallback(() => {
    gridDataSource?.insert(newSiteData.current);
  }, [gridDataSource]);

  const onUpdateClick = useCallback(() => {
    let siteToUpdate = updateSiteData.current;
    gridDataSource?.update(siteToUpdate.id, siteToUpdate);
  }, [gridDataSource]);

  const onDeleteClick = useCallback(() => {
    let dataGrid = gridRef.current?.instance;
    dataGrid?.deleteRow(selectedRow.current);
    dataGrid?.saveEditData();
  }, [selectedRow]);

  function renderLabel() {
    return <div className="grid-header">Site</div>;
  }

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Categories");

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Categories.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  const onUpdateCategoryClick = useCallback(() => {
    setPopupUpdateVisible(true);
  }, []);

  const exportFormats = ["xlsx"];

  const allowedUsers = [RoleType.Admin];
  return (
    <ProtectedRoute allowedRoles={allowedUsers}>
      <div className="view shortlink-list">
        <div className="view-wrapper view-wrapper-shortlink">
          <DataGrid
            key="siteOrigin"
            className="grid"
            ref={gridRef}
            id="categorysGrid"
            dataSource={gridDataSource}
            showRowLines
            showBorders
            onExporting={onExporting}
            onDataErrorOccurred={onDataErrorOccurred}
            allowColumnReordering
            focusedRowEnabled
            rowAlternationEnabled
            remoteOperations={false}
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <LoadPanel showPane={false} />
            <ColumnChooser enabled />
            <FilterPanel visible={true} />
            <Export enabled allowExportSelectedData formats={exportFormats} />
            <HeaderFilter visible />
            <Selection
              selectAllMode="allPages"
              showCheckBoxesMode="always"
              mode="multiple"
            />
            <Scrolling mode="virtual" />
            <Sorting mode="multiple" />
            <SearchPanel visible={true} placeholder="Search" />
            <Column dataField="id" visible={false} />
            <Column
              dataField="name"
              caption="Name"
              allowFiltering={true}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column
              dataField="domain"
              caption="Domain"
              allowFiltering={true}
              visible={true}
            ></Column>
            <Column
              dataField="defaultUrl"
              caption="Default Url"
              allowFiltering={true}
              visible={true}
            ></Column>
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                render={renderLabel}
              ></Item>

              <Item location="after" locateInMenu="auto">
                <Button
                  icon="plus"
                  text="Add Site"
                  type="default"
                  stylingMode="contained"
                  onClick={onAddSiteClick}
                />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "edit",
                  type: "warning",
                  onClick: updateSiteClick,
                }}
              />
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "trash",
                  type: "warning",
                  onClick: onDeleteSiteClick,
                }}
              />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxTextBox"
                options={{
                  mode: "search",
                  placeholder: "Search",
                  onValueChanged: (e) => {
                    const text = e.component.option("value");
                    const grid = gridRef.current?.instance;
                    grid?.searchByText(text);
                  },
                }}
              />
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "refresh",
                  type: "success",
                  stylingMode: "outlined",
                  onClick: () => {
                    setRefresh(!refresh);
                  },
                }}
              />
            </Toolbar>
          </DataGrid>
          <FormPopup
            title="New Site"
            visible={popupNewVisible}
            setVisible={changeNewPopupVisibility}
            actionButtonTitle="Save"
            onAction={onSaveClick}
          >
            <SiteForm
              initData={formDataDefaults}
              visible={popupUpdateVisible}
              onDataChanged={onDataCreateChanged}
            ></SiteForm>
          </FormPopup>

          <FormPopup
            title="Update Site"
            visible={popupUpdateVisible}
            setVisible={changeUpdatePopupVisibility}
            actionButtonTitle="Save"
            onAction={onUpdateClick}
          >
            <SiteForm
              initData={selectedSite}
              visible={popupUpdateVisible}
              onDataChanged={onDataUpdateChanged}
            ></SiteForm>
          </FormPopup>
          <FormPopup
            title="Delete Site"
            visible={popupDeleteVisible}
            setVisible={changeDeletePopupVisibility}
            onAction={onDeleteClick}
            actionButtonTitle="Delete"
          >
            <SiteDelete
              site={selectedSite}
              onDataChanged={onDataDeleteChanged}
            ></SiteDelete>
          </FormPopup>
        </div>
      </div>
    </ProtectedRoute>
  );
}
