import { useEffect, useRef, useState } from "react";
import "./log.scss";
import DataGrid, {
  Column,
  DataGridTypes,
  Export,
  FilterPanel,
  HeaderFilter,
  Item,
  Scrolling,
  Search,
  SearchPanel,
  Sorting,
  Toolbar,
  LoadPanel,
  ColumnChooser,
  Selection,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import { exportDataGrid as exportDataGridToXLSX } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { Workbook } from "exceljs";
import ProtectedRoute from "../../components/utils/protected-route/ProtectedRoute";
import { useShortLinkService } from "../../hooks/useShortLinkService";
import { Logs, RoleType } from "../../types/shortlink";

export default function Log() {
  const [refresh, setRefresh] = useState<boolean>(false);

  const gridRef = useRef<DataGrid>(null);
  const [gridDataSource, setGridDataSource] =
    useState<DataSource<Logs[], string>>();

  const [service] = useShortLinkService();

  useEffect(() => {
    refreshLogs();
  }, [refresh]);

  const refreshLogs = () => {
    setGridDataSource(
      new DataSource({
        key: "url",
        load: () => service.getAllLogs().catch((error) => {}),
      })
    );
  };

  function renderLabel() {
    return <div className="grid-header">Error Logs</div>;
  }

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Logs");

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Logs.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  const exportFormats = ["xlsx"];

  const allowedUsers = [RoleType.User, RoleType.Admin];

  return (
    <ProtectedRoute allowedRoles={allowedUsers}>
      <div className="view shortlink-list">
        <div className="view-wrapper view-wrapper-shortlink">
          <DataGrid
            key="siteOrigin"
            className="grid"
            ref={gridRef}
            id="logsGrid"
            dataSource={gridDataSource}
            showRowLines
            showBorders
            onExporting={onExporting}
            allowColumnReordering
            focusedRowEnabled
            rowAlternationEnabled
            remoteOperations={false}
          >
            <LoadPanel showPane={false} />
            <ColumnChooser enabled />
            <FilterPanel visible={true} />
            <Export enabled allowExportSelectedData formats={exportFormats} />
            <HeaderFilter visible />
            <Selection
              selectAllMode="allPages"
              showCheckBoxesMode="always"
              mode="multiple"
            />
            <Scrolling mode="virtual" />
            <Sorting mode="multiple" />
            <SearchPanel visible={true} placeholder="Search" />
            <Column dataField="url" visible={true}>
              <HeaderFilter>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField="views" visible={true}>
              <HeaderFilter>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>

            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                render={renderLabel}
              ></Item>

              <Item name="exportButton" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxTextBox"
                options={{
                  mode: "search",
                  placeholder: "Search",
                  onValueChanged: (e) => {
                    const text = e.component.option("value");
                    const grid = gridRef.current?.instance;
                    grid?.searchByText(text);
                  },
                }}
              />
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "refresh",
                  type: "success",
                  stylingMode: "outlined",
                  onClick: () => {
                    setRefresh(!refresh);
                  },
                }}
              />
            </Toolbar>
          </DataGrid>
        </div>
      </div>
    </ProtectedRoute>
  );
}
