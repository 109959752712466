import React, {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Button, ButtonTypes } from "devextreme-react/button";
import { ScrollView } from "devextreme-react/scroll-view";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";

import ValidationGroup from "devextreme-react/validation-group";
import { FormQrCode } from "../../utils/form-qrcode/FormQrCode";
import { useScreenSize } from "../../../utils/media-query";
import {
  Category,
  CategoryNone,
  ShortLink,
  ShortLinkRequest,
  SiteNone,
  defaultShortLink,
  defaultShortLinkRequest,
} from "../../../types/shortlink";
import { FormTextbox } from "../../utils/form-textbox/FormTextbox";
import { ColorBox, SelectBox } from "devextreme-react";
import { useCategoryService } from "../../../hooks/useCategoryService";
import { FormSelectBox } from "../../utils/form-selectbox/FormSelectBox";
import DataSource from "devextreme/data/data_source";

export const ShortLinkPreviewDetails = ({
  shortlink,
  isOpened,
  categories,
  changePanelOpened,
  onDataChanged,
  changePanelPinned,
  onUpdate,
}: {
  shortlink: ShortLink | null;
  isOpened: boolean;
  categories: Category[] | null;
  changePanelOpened: (value: boolean) => void;
  onDataChanged: (data: any) => void;
  changePanelPinned: () => void;
  onUpdate: (data: ShortLink, category: Category) => void;
}) => {
  const [isPinned, setIsPinned] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { isLarge, isMedium } = useScreenSize();
  const [category, setCategory] = useState<Category>(CategoryNone);
  const [shortLinkData, setShortLinkData] = useState<ShortLink>(
    shortlink ?? defaultShortLink
  );

  const categoryBox = useRef<SelectBox>(null);

  useEffect(() => {
    setShortLinkData(shortlink ?? defaultShortLink);
    let selectedCategory =
      categories?.find((x) => x.name === shortLinkData.category) ??
      CategoryNone;
    setCategory(selectedCategory);
  }, [shortlink]);

  const navigate = useNavigate();
  const updateField = (field: string) => (value: any) => {
    var newShortLink = { ...shortLinkData, ...{ [field]: value } };
    setShortLinkData(newShortLink);
    onDataChanged(newShortLink);
  };

  const categoryChanged = (value: Category) => {
    var newShortLink = { ...shortLinkData, ...{ category: value.name } };
    setShortLinkData(newShortLink);
    setCategory(value);
    onDataChanged(newShortLink);
  };

  useEffect(() => {
    changePanelPinned();
  }, [isPinned]);

  const toggleEditHandler = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const onSaveClick = ({ validationGroup }: ButtonTypes.ClickEvent) => {
    if (!validationGroup.validate().isValid) return;
    setIsEditing(!isEditing);
    onUpdate(shortLinkData, category);
  };

  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  const onClosePanelClick = useCallback(() => {
    setIsPinned(false);
    changePanelOpened(false);
  }, []);

  const categoriesDataSource = new DataSource({
    store: {
      data: categories ?? [CategoryNone],
      type: "array",
      key: "name",
    },
  });
  return (
    <div
      id="shortlink-panel"
      className={classNames({
        panel: true,
        open: isOpened,
        pin: isPinned && (isLarge || isMedium),
      })}
    >
      <div className="data-wrapper">
        <Toolbar className="panel-toolbar">
          <ToolbarItem location="before">
            <span className="shortlink-name value">{shortLinkData?.id}</span>
          </ToolbarItem>
          <ToolbarItem location="before">
            {/* <ContactStatus text={shortlink.id} />   */}
          </ToolbarItem>
          <ToolbarItem location="after" visible={isLarge || isMedium}>
            <Button
              icon={isPinned ? "pin" : "unpin"}
              stylingMode="text"
              onClick={onPinClick}
            />
          </ToolbarItem>
          <ToolbarItem location="after">
            <Button
              icon="close"
              stylingMode="text"
              onClick={onClosePanelClick}
            />
          </ToolbarItem>
        </Toolbar>
        <ScrollView className="panel-scroll">
          <ValidationGroup>
            <div className="data-part border">
              <Form
                className={classNames({
                  "plain-styled-form": true,
                  "view-mode": !isEditing,
                })}
              >
                <GroupItem colCount={2} cssClass="photo-row">
                  <ColCountByScreen xs={2} />
                  <FormItem cssClass="photo-box">
                    <FormQrCode
                      value={shortLinkData?.siteOrigin ?? ""}
                      darkColor={shortLinkData?.darkColor ?? ""}
                      lightColor={shortLinkData?.lightColor ?? ""}
                      name={shortLinkData?.id ?? ""}
                      size={124}
                    />
                  </FormItem>
                  <GroupItem>
                    <FormItem cssClass="accent">
                      <FormTextbox
                        label="Id"
                        value={shortLinkData?.id ?? ""}
                        isEditing={true}
                        onValueChange={updateField("id")}
                      />
                    </FormItem>
                    <FormItem>
                      <SelectBox
                        dataSource={categoriesDataSource}
                        value={shortLinkData?.category}
                        displayExpr="name"
                        valueExpr="name"
                        acceptCustomValue={true}
                        onValueChange={categoryChanged}
                      />
                    </FormItem>
                    <FormItem cssClass="accent">
                      <FormTextbox
                        label="Site"
                        value={shortLinkData?.siteName ?? ""}
                        isEditing={true}
                        onValueChange={updateField("siteName")}
                      />
                    </FormItem>
                  </GroupItem>
                </GroupItem>

                <GroupItem cssClass="shortlink-fields-group">
                  <FormItem>
                    <FormTextbox
                      label="Origin"
                      value={shortLinkData?.siteOrigin ?? ""}
                      isEditing={true}
                      onValueChange={updateField("siteOrigin")}
                      icon="home"
                    />
                  </FormItem>
                  <FormItem>
                    <FormTextbox
                      label="Destination"
                      value={shortLinkData?.destinationUrl ?? ""}
                      isEditing={!isEditing}
                      onValueChange={updateField("destinationUrl")}
                      icon="home"
                    />
                  </FormItem>
                  <FormItem>
                    <ColorBox
                      label="Light Color"
                      defaultValue="#ffffff"
                      value={shortLinkData?.lightColor}
                      readOnly={!isEditing}
                      onValueChange={updateField("lightColor")}
                    />
                  </FormItem>
                  <FormItem>
                    <ColorBox
                      label="Dark Color"
                      defaultValue="#000000"
                      value={shortLinkData?.darkColor}
                      readOnly={!isEditing}
                      onValueChange={updateField("darkColor")}
                    />
                  </FormItem>
                </GroupItem>
              </Form>
            </div>
            <div className="data-part data-part-toolbar border">
              <Toolbar>
                <ToolbarItem location="before" visible={!isEditing}>
                  <Button
                    icon="edit"
                    text="Edit"
                    stylingMode="outlined"
                    type="default"
                    onClick={toggleEditHandler}
                  />
                </ToolbarItem>
                <ToolbarItem location="before" visible={isEditing}>
                  <Button
                    text="Save"
                    icon="save"
                    stylingMode="outlined"
                    type="default"
                    onClick={onSaveClick}
                  />
                </ToolbarItem>
                <ToolbarItem location="before" visible={isEditing}>
                  <Button
                    text="Cancel"
                    stylingMode="text"
                    onClick={toggleEditHandler}
                  />
                </ToolbarItem>
              </Toolbar>
            </div>
          </ValidationGroup>
        </ScrollView>
      </div>
    </div>
  );
};
