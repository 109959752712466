import { Site } from "../types/shortlink";
import { useApi } from "./useApi";

interface SiteService {
  create: (shortLink: Site) => Promise<any>;
  delete: (id: string) => Promise<any>;
  update: (shortLink: Site) => Promise<any>;
  getAll: () => Promise<any>;
}

export const useSiteService = (): [SiteService] => {
  const { requests } = useApi();

  const siteService: SiteService = {
    create: (site: Site) => requests.post("/api/v1/sites", site),
    delete: (id: string) => requests.del(`/api/v1/sites/${id}`),
    update: (site: Site) => requests.put("/api/v1/sites", site),
    getAll: () => requests.get<Site[]>("/api/v1/sites"),
  };

  return [siteService];
};
