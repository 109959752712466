import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import customAxios from "./axiosSetup";

// Define the shape of your API response if possible
export interface ApiResponse<T = any> {
  data: T;
}

interface UseApiReturnType {
  requests: {
    get: <T>(url: string) => Promise<T>;
    post: <T>(url: string, body: any) => Promise<ApiResponse<T>>;
    put: <T>(url: string, body: any) => Promise<ApiResponse<T>>;
    del: <T>(url: string) => Promise<ApiResponse<T>>;
  };
}

export const useApi = (): UseApiReturnType => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const api = customAxios;

  const refreshAccessToken = async () => {
    const token = await instance.acquireTokenSilent({
      scopes: ["api://fd94f8c6-501b-41eb-8bb1-5bd28eaf569e/reader"],
      account: account!,
    });
    return token.accessToken;
  };

  api.interceptors.request.use(
    async (config) => {
      config.headers["x-api-challenge"] =
        localStorage.getItem("challenge") || "no-key-found";
      const token = await refreshAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const responseBody = <T,>(response: ApiResponse<T>) => {
    if (response && response.data !== undefined) {
      return response.data;
    } else {
      throw new Error("Response data is undefined");
    }
  };

  const requests = {
    get: <T,>(url: string) => api.get<T>(url).then(responseBody),
    post: <T,>(url: string, body: any) =>
      api.post<ApiResponse<T>>(url, body).then(responseBody),
    put: <T,>(url: string, body: any) =>
      api.put<ApiResponse<T>>(url, body).then(responseBody),
    del: (url: string) => api.delete(url),
  };

  return {
    requests,
  };
};
