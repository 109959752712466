import React, {
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";

import "./ShortLinkPreviewPanel.scss";

import { ShortLinkPreviewDetails } from "./ShortLinkPreviewDetails";
import { Category, ShortLink } from "../../../types/shortlink";
import { withLoadPanel } from "../../../utils/withLoadPanel";

const ShortLinkPreviewPanelWithLoadPanel = withLoadPanel(
  ShortLinkPreviewDetails
);

export const ShortLinkPreviewPanel = ({
  shortLinkId,
  shortLink,
  isOpened,
  categories,
  changePanelOpened,
  changePanelPinned,
  onUpdate,
}: {
  shortLinkId: string | null;
  shortLink: ShortLink;
  categories: Category[];
  isOpened: boolean;
  changePanelOpened: (value: boolean) => void;
  changePanelPinned: () => void;
  onUpdate: (data: ShortLink, category: Category) => void;
}) => {
  const [data, setData] = useState<ShortLink>();

  const loadData = useCallback(() => {
    if (!shortLinkId) return;
    setData(shortLink);
  }, [shortLinkId]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<ShortLink | undefined>) => {
      setData(data);
    },
    []
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <ShortLinkPreviewPanelWithLoadPanel
      shortlink={shortLink}
      isOpened={isOpened}
      hasData={!!data}
      categories={categories}
      onUpdate={onUpdate}
      onDataChanged={onDataChanged}
      changePanelOpened={changePanelOpened}
      changePanelPinned={changePanelPinned}
      panelProps={{
        position: { of: ".panel" },
        container: ".panel",
      }}
    />
  );
};
