import React, { useEffect, useState } from "react";

import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
  PatternRule,
} from "devextreme-react/form";

import { getSizeQualifier } from "../../../utils/media-query";
import {
  Category,
  CategoryNone,
  ShortLink,
  ShortLinkRequest,
  Site,
  SiteNone,
} from "../../../types/shortlink";
import { FormTextbox } from "../../utils/form-textbox/FormTextbox";
import ColorBox from "devextreme-react/color-box";
import { useSiteService } from "../../../hooks/useSiteService";
import { FormQrCode } from "../../utils/form-qrcode/FormQrCode";
import { useCategoryService } from "../../../hooks/useCategoryService";
import { RequiredRule } from "devextreme-react/validator";
import { FormSelectBox } from "../../utils/form-selectbox/FormSelectBox";

export const ShortLinkNewForm = ({
  initData,
  onDataChanged,
}: {
  initData: ShortLink;
  onDataChanged: (data) => void;
}) => {
  const [sites, setSites] = useState<Site[]>([SiteNone]);
  const [site, setSite] = useState<Site>(SiteNone);
  const [categories, setCategories] = useState<Category[]>([CategoryNone]);
  const [category, setCategory] = useState<Category>(SiteNone);
  const [orignPath, setOriginPath] = useState<string>("");
  const [newShortLinkData, setNewShortLinkData] = useState<ShortLink>({
    ...initData,
  });
  const readOnlySites = { "aria-label": "ReadOnly Sites" };
  const [siteService] = useSiteService();
  const [categoryService] = useCategoryService();

  useEffect(() => {
    siteService
      .getAll()
      .then((response) => {
        setSites(response);
      })
      .catch((error) => {});

    categoryService
      .getAll()
      .then((response) => {
        setCategories(response);
      })
      .catch((error) => {});

    setNewShortLinkData({ ...initData });
  }, [initData]);

  const updateField = (field: string) => (value) => {
    const newData = { ...newShortLinkData, ...{ [field]: value } };
    setNewShortLinkData(newData);
    onDataChanged(newData);
  };

  const updateFieldCategory = (category) => {
    const newData = { ...newShortLinkData, ...{ category: category } };
    setNewShortLinkData(newData);
    onDataChanged(newData);
  };

  const updateOrigin = (field: string) => (value) => {
    setOriginPath(value);
    const newData = {
      ...newShortLinkData,
      ...{ siteOrigin: site.domain + value },
    };
    setNewShortLinkData(newData);
    onDataChanged(newData);
  };
  const onSiteChanged = (site: Site) => {
    if (!site) return;
    setSite(site);
    const newData = {
      ...newShortLinkData,
      ...{
        siteOrigin: site.domain + orignPath,
        siteName: site.name,
        site: site.domain,
      },
    };
    setNewShortLinkData(newData);
    onDataChanged(newData);
  };

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem colSpan={2}>
          <FormSelectBox
            items={categories}
            defaultValue={categories[0]}
            value={newShortLinkData.category}
            displayExpr="name"
            label="Category"
            isEditing={false}
            onValueChange={updateFieldCategory}
          />
        </FormItem>
        <FormItem>
          <FormSelectBox
            items={sites}
            defaultValue={sites[0]}
            value={site}
            displayExpr="name"
            label="Site"
            isEditing={false}
            onValueChange={onSiteChanged}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Origin Path"
            value={orignPath}
            isEditing={false}
            onValueChange={updateOrigin("id")}
          />
          <RequiredRule />
        </FormItem>

        <FormItem colSpan={2}>
          <FormTextbox
            label="Origin Url (Preview}"
            value={newShortLinkData.siteOrigin}
            isEditing={true}
            onValueChange={updateOrigin}
          />
        </FormItem>
        <FormItem colSpan={2}>
          <FormTextbox
            label="Destination Url"
            value={newShortLinkData.destinationUrl}
            isEditing={false}
            onValueChange={updateField("destinationUrl")}
          >
            <PatternRule
              message="This is not a valid url."
              pattern={
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})(\/[\w\/ \.-]*)*(\?[\w=&-]*)?(#[\w-]*)?\/?$/
              }
            ></PatternRule>
          </FormTextbox>
        </FormItem>
        <FormItem>
          <ColorBox
            label="Light Color"
            defaultValue="#ffffff"
            onValueChange={updateField("lightColor")}
          />
        </FormItem>
        <FormItem>
          <ColorBox
            label="Dark Color"
            defaultValue="#000000"
            onValueChange={updateField("darkColor")}
          />
        </FormItem>
      </GroupItem>

      <GroupItem colCount={2} cssClass="photo-row">
        <ColCountByScreen xs={2} />
        <FormItem cssClass="photo-box">
          <FormQrCode
            value={newShortLinkData.siteOrigin}
            darkColor={newShortLinkData.darkColor}
            lightColor={newShortLinkData.lightColor}
            name="newShortLink"
            size={124}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};
