import React, { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import "./UserPanel.scss";
import type { UserPanelProps } from "../../../types";
import { useAccount, useMsal } from "@azure/msal-react";
import useProfile from "../../../hooks/useProfile";

export default function UserPanel({ menuMode }: UserPanelProps) {
  //const { user, signOut } = useMsal();

  const { instance, accounts } = useMsal();
  const user = useAccount(accounts[0] || {});

  const navigate = useNavigate();

  var { profile } = useProfile();
  const navigateToProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const signOut = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/", // redirects the top level app after logout
    });
  };

  const menuItems = useMemo(
    () => [
      // {
      //   text: "Profile",
      //   icon: "user",
      //   onClick: navigateToProfile,
      // },
      {
        text: "Logout",
        icon: "runner",
        onClick: signOut,
      },
    ],
    [navigateToProfile, signOut]
  );
  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div></div>
        <div className={"image-container"}>
          <div
            style={{
              background: `url(${profile?.pictureUrl}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        </div>
        <div className={"user-name"}>{user!.name}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
