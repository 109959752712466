import React, { useEffect, useState } from "react";

import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";

import { getSizeQualifier } from "../../../utils/media-query";
import { ShortLink } from "../../../types/shortlink";

export const ShortLinkDeleteForm = ({
  shortLink,
  onDataChanged,
}: {
  shortLink: ShortLink;
  onDataChanged: (data) => void;
}) => {
  useEffect(() => {
    onDataChanged(shortLink);
  }, [shortLink]);
  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem colSpan={2}>
          <div>
            Would you like to delete the Shortlink <b>{shortLink.siteOrigin}</b>
            ?
          </div>
        </FormItem>
      </GroupItem>
    </Form>
  );
};
