import { Category, Site } from "../types/shortlink";
import { useApi } from "./useApi";

interface CategoryService {
  create: (shortLink: Category) => Promise<any>;
  delete: (id: string) => Promise<any>;
  update: (shortLink: Category) => Promise<any>;
  getAll: () => Promise<any>;
}

export const useCategoryService = (): [CategoryService] => {
  const { requests } = useApi();

  const categoryService: CategoryService = {
    create: (site: Category) => requests.post("/api/v1/categories", site),
    delete: (id: string) => requests.del(`/api/v1/categories/${id}`),
    update: (site: Category) => requests.put("/api/v1/categories", site),
    getAll: () => requests.get<Category[]>("/api/v1/categories"),
  };

  return [categoryService];
};
