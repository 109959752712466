import { RoleType } from "./types/shortlink";

export const navigation = [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    roles: [RoleType.Admin, RoleType.User],
  },
  {
    text: "ShortLinks",
    icon: "link",
    path: "/shortlinks",
    roles: [RoleType.Admin, RoleType.User],
  },
  {
    text: "Category",
    path: "/category",
    icon: "mediumiconslayout",
    roles: [RoleType.Admin, RoleType.User],
  },
  {
    text: "Site",
    path: "/site",
    icon: "globe",
    roles: [RoleType.Admin],
  },
  {
    text: "Log",
    path: "/log",
    icon: "textdocument",
    roles: [RoleType.Admin],
  },
];
