import { useMsal } from "@azure/msal-react";
import { RoleType } from "../types/shortlink"; // Assuming RoleType is exported from a types file
// Define the RoleType enum

// Define the type for the token claims if needed more specifically
interface IdTokenClaims {
  roles?: RoleType[];
}

const useUserRoles = () => {
  const { accounts } = useMsal();
  // Extract the roles from the idTokenClaims, cast to IdTokenClaims to access the roles property
  const roles: RoleType[] =
    (accounts[0]?.idTokenClaims as IdTokenClaims)?.roles || [];

  // Function to check if the user has a specific role
  const hasRole = (role: RoleType): boolean => roles.includes(role);

  // Function to check if user has at least one of the specified roles
  const hasPageAccess = (pageRoles: RoleType[]): boolean => {
    return pageRoles.some((role) => hasRole(role)) || isAdmin();
  };

  // Method to check if user is an admin
  const isAdmin = (): boolean => hasRole(RoleType.Admin);

  return { roles, hasRole, hasPageAccess, isAdmin };
};

export default useUserRoles;
