import React, { useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { useMsal } from "@azure/msal-react";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { loginRequest } from "../../../authConfig";
import "./LoginForm.scss";
import miniLogo from "../../../assets/Logo_CSL_RVB.png";

export default function LoginForm() {
  const { instance } = useMsal();

  return (
    <div className="login-container">
      <div className="app-logo">
        <img src={miniLogo} alt="App Logo" />
      </div>
      <form className="login-form">
        <MicrosoftLoginButton onClick={() => instance.loginPopup(loginRequest)}>
          Sign in with Microsoft
        </MicrosoftLoginButton>
      </form>
    </div>
  );
}
