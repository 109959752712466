import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";

import { getSizeQualifier } from "../../../utils/media-query";
import { Site } from "../../../types/shortlink";
import { useEffect } from "react";

export const SiteDelete = ({
  site,
  onDataChanged,
}: {
  site: Site;
  onDataChanged: (data) => void;
}) => {
  useEffect(() => {
    onDataChanged(site);
  }, [site]);

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem colSpan={2}>
          <div>
            Would you like to delete the Site <b>{site.name}</b>
          </div>
        </FormItem>
      </GroupItem>
    </Form>
  );
};
