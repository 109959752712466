export interface ShortLink {
  id: string;
  siteOrigin: string;
  site: string;
  siteName: string;
  destinationUrl: string;
  lightColor: string;
  darkColor: string;
  category: any;
  views: number;
}

export interface ShortLinkRequest {
  origin: string;
  destination: string;
  site: string;
  lightColor: string;
  darkColor: string;
  category: Category;
}

export const defaultShortLinkRequest: ShortLinkRequest = {
  origin: "",
  site: "",
  destination: "",
  lightColor: "#ffffff", // assuming white as default light color
  darkColor: "#000000", // assuming black as default dark color
  category: { id: "", name: "" },
};

export const toShortLinkRequest = (
  shortLink: ShortLink,
  category: Category
): ShortLinkRequest => {
  return {
    origin: shortLink.siteOrigin,
    destination: shortLink.destinationUrl,
    site: shortLink.siteName,
    lightColor: shortLink.lightColor,
    darkColor: shortLink.darkColor,
    category: category,
  };
};

export const toRequest = (shortLink: ShortLink): ShortLinkRequest => {
  return {
    origin: shortLink.siteOrigin,
    destination: shortLink.destinationUrl,
    site: shortLink.siteName,
    lightColor: shortLink.lightColor,
    darkColor: shortLink.darkColor,
    category: { id: "", name: shortLink.category },
  };
};

export const toNewRequest = (shortLink: ShortLink): ShortLinkRequest => {
  return {
    origin: shortLink.siteOrigin,
    destination: shortLink.destinationUrl,
    site: shortLink.siteName,
    lightColor: shortLink.lightColor,
    darkColor: shortLink.darkColor,
    category: shortLink.category,
  };
};

export const defaultShortLink: ShortLink = {
  id: "",
  siteOrigin: "",
  site: "",
  siteName: "",
  destinationUrl: "",
  lightColor: "#ffffff", // assuming white as default light color
  darkColor: "#000000", // assuming black as default dark color
  category: "",
  views: 0,
};

export interface Site {
  id: string;
  name: string;
  domain: string;
  defaultUrl: string;
}

export interface Logs {
  url: string;
  views: number;
}

export const SiteNone: Site = {
  id: "",
  name: "",
  domain: "",
  defaultUrl: "",
};

export interface Category {
  id: string;
  name: string;
}

export const CategoryNone: Category = {
  id: "",
  name: "",
};

export enum RoleType {
  User = "User",
  Admin = "Admin",
}
