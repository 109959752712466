import React, { useCallback, useRef, useState } from "react";
import "./category-list.scss";
import DataGrid, {
  Column,
  DataGridTypes,
  Export,
  FilterPanel,
  HeaderFilter,
  Item,
  Scrolling,
  Search,
  SearchPanel,
  Sorting,
  Toolbar,
  LoadPanel,
  ColumnChooser,
  Selection,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { CategoryNone, Category, RoleType } from "../../types/shortlink";
import { FormPopup } from "../../components/utils/form-popup/FormPopup";
import Button from "devextreme-react/button";
import { CategoryForm } from "../../components/library/category-form/CategoryForm";
import { exportDataGrid as exportDataGridToXLSX } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { Workbook } from "exceljs";
import { CategoryDelete } from "../../components/library/category-delete/CategoryDelete";
import { useCategoryService } from "../../hooks/useCategoryService";
import ProtectedRoute from "../../components/utils/protected-route/ProtectedRoute";
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import { AxiosError } from "axios";
import { error } from "console";

export default function CategoryList() {
  const selectedRow = useRef<number>(0);
  const [popupNewVisible, setPopupNewVisible] = useState<boolean>(false);
  const [popupUpdateVisible, setPopupUpdateVisible] = useState<boolean>(false);

  const [popupDeleteVisible, setPopupDeleteVisible] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] =
    useState<Category>(CategoryNone);

  const [formDataDefaults, setFormDataDefaults] = useState<Category>({
    ...CategoryNone,
  });

  const gridRef = useRef<DataGrid>(null);

  const [categoryService] = useCategoryService();

  const newCategoryData = useRef<Category>(CategoryNone);
  const updateCategoryData = useRef<Category>(CategoryNone);
  let deleteCategoryData: Category;

  const onLoad = async (loadOptions: LoadOptions<Category>) =>
    await categoryService.getAll().catch((error) => {});

  const onInsert = async (category: Category) => {
    return await categoryService.create(category).catch((error) => {});
  };

  const onInserted = (category: Category, key: string) => {
    notify(
      {
        message: `New category "${category.name}" saved`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const onUpdate = async (key: string, category: Category) => {
    return categoryService.update(category);
  };

  const onUpdated = (key: string, category: Category) => {
    notify(
      {
        message: `Shortlink "${category.name}" updated`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const onRemove = async (key: string) => categoryService.delete(key);

  const onRemoved = async (key: string) => {
    notify(
      {
        message: `The category "${key}" was deleted with success.`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const [gridDataSource, setGridDataSource] = useState<
    CustomStore<Category, string>
  >(
    new CustomStore({
      key: "id",
      load: onLoad,
      update: onUpdate,
      insert: onInsert,
      remove: onRemove,
      onInserted: onInserted,
      onUpdated: onUpdated,
      onRemoved: onRemoved,
      errorHandler: (error) => {
        let errorMsg: AxiosError = error as AxiosError;

        console.log(errorMsg.response?.data![0].message);
      },
    })
  );

  const onDataDeleteChanged = useCallback((data: Category) => {
    deleteCategoryData = data;
  }, []);

  const onDataCreateChanged = useCallback((data: Category) => {
    newCategoryData.current = data;
  }, []);

  const onDataUpdateChanged = useCallback((data: Category) => {
    updateCategoryData.current = data;
  }, []);

  const changeNewPopupVisibility = useCallback((isVisble) => {
    setPopupNewVisible(isVisble);
  }, []);

  const changeUpdatePopupVisibility = useCallback((isVisble) => {
    setPopupUpdateVisible(isVisble);
  }, []);

  const changeDeletePopupVisibility = useCallback((isVisble) => {
    setPopupDeleteVisible(isVisble);
  }, []);

  // const onDataErrorOccurred = useCallback(
  //   (event: DataGridTypes.DataErrorOccurredEvent) => {
  //     console.log(event);
  //     // //if (!row) return;

  //     // const categoryData: Category = row.data as Category;
  //     // selectedRow.current = row.rowIndex;
  //     // setSelectedCategory(categoryData);

  //     console.log(event.error?.message);
  //   },
  //   []
  // );

  function checkForElement(
    selector,
    callback,
    interval = 500,
    timeout = 10000
  ) {
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const element = document.querySelector(selector);

      if (element) {
        clearInterval(intervalId);
        callback(element);
      } else if (Date.now() - startTime > timeout) {
        clearInterval(intervalId);
        console.log("Timeout waiting for element");
      }
    }, interval);
  }

  const onDataErrorOccurred = useCallback(
    (event: DataGridTypes.DataErrorOccurredEvent) => {
      let customErrorMessage: String | null = null;

      let axiosError: AxiosError = event.error as AxiosError;

      if (axiosError?.response?.data) {
        customErrorMessage =
          axiosError.response.data[0].message || customErrorMessage;
      }
      checkForElement(".dx-error-message", (element) => {
        if (customErrorMessage) element.innerHTML = customErrorMessage;
      });
    },
    []
  );

  const onFocusedRowChanged = useCallback(
    ({ row }: DataGridTypes.FocusedRowChangedEvent) => {
      if (!row) return;

      const categoryData: Category = row.data as Category;
      selectedRow.current = row.rowIndex;
      setSelectedCategory(categoryData);
    },
    []
  );

  const onAddCategoryClick = useCallback(() => {
    setPopupNewVisible(true);
  }, []);

  const onDeleteCategoryClick = useCallback(() => {
    setPopupDeleteVisible(true);
  }, []);

  const onUpdateCategoryClick = useCallback(() => {
    setPopupUpdateVisible(true);
  }, []);

  const updateCategoryClick = () => {
    setSelectedCategory(selectedCategory);
    setPopupUpdateVisible(true);
  };

  const onUpdateClick = useCallback(() => {
    let categoryToUpdate = updateCategoryData.current;
    gridDataSource?.update(categoryToUpdate.id, categoryToUpdate);
  }, [gridDataSource]);

  const onSaveClick = useCallback(() => {
    gridDataSource?.insert(newCategoryData.current);
  }, [gridDataSource]);

  const onDeleteClick = useCallback(() => {
    let dataGrid = gridRef.current?.instance;
    dataGrid?.deleteRow(selectedRow.current);
    dataGrid?.saveEditData();
  }, [selectedRow]);

  function renderLabel() {
    return <div className="grid-header">Category</div>;
  }

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Categories");

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Categories.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  const exportFormats = ["xlsx"];

  const allowedUsers = [RoleType.User, RoleType.Admin];

  return (
    <ProtectedRoute allowedRoles={allowedUsers}>
      <div className="view shortlink-list">
        <div className="view-wrapper view-wrapper-shortlink">
          <DataGrid
            key="siteOrigin"
            className="grid"
            ref={gridRef}
            id="categorysGrid"
            dataSource={gridDataSource}
            showRowLines
            showBorders
            onExporting={onExporting}
            allowColumnReordering
            onDataErrorOccurred={onDataErrorOccurred}
            focusedRowEnabled
            rowAlternationEnabled
            remoteOperations={false}
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <LoadPanel showPane={false} />
            <ColumnChooser enabled />
            <FilterPanel visible={true} />
            <Export enabled allowExportSelectedData formats={exportFormats} />
            <HeaderFilter visible />
            <Selection
              selectAllMode="allPages"
              showCheckBoxesMode="always"
              mode="multiple"
            />
            <Scrolling mode="virtual" />
            <Sorting mode="multiple" />
            <SearchPanel visible={true} placeholder="Search" />
            <Column dataField="id" visible={false} />
            <Column
              dataField="name"
              caption="Name"
              allowFiltering={true}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>

            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                render={renderLabel}
              ></Item>

              <Item location="after" locateInMenu="auto">
                <Button
                  icon="plus"
                  text="Add Category"
                  type="default"
                  stylingMode="contained"
                  onClick={onAddCategoryClick}
                />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "edit",
                  type: "warning",
                  onClick: updateCategoryClick,
                }}
              />
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "trash",
                  type: "warning",
                  onClick: onDeleteCategoryClick,
                }}
              />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxTextBox"
                options={{
                  mode: "search",
                  placeholder: "Search",
                  onValueChanged: (e) => {
                    const text = e.component.option("value");
                    const grid = gridRef.current?.instance;
                    grid?.searchByText(text);
                  },
                }}
              />
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "refresh",
                  type: "success",
                  stylingMode: "outlined",
                  onClick: () => {
                    setRefresh(!refresh);
                  },
                }}
              />
            </Toolbar>
          </DataGrid>

          <FormPopup
            title="New Category"
            visible={popupNewVisible}
            setVisible={changeNewPopupVisibility}
            actionButtonTitle="Save"
            onAction={onSaveClick}
          >
            <CategoryForm
              visible={popupUpdateVisible}
              initData={formDataDefaults}
              onDataChanged={onDataCreateChanged}
            ></CategoryForm>
          </FormPopup>

          <FormPopup
            title="Update Category"
            visible={popupUpdateVisible}
            setVisible={changeUpdatePopupVisibility}
            actionButtonTitle="Save"
            onAction={onUpdateClick}
          >
            <CategoryForm
              initData={selectedCategory}
              visible={popupUpdateVisible}
              onDataChanged={onDataUpdateChanged}
            ></CategoryForm>
          </FormPopup>

          <FormPopup
            title="Delete Category"
            visible={popupDeleteVisible}
            setVisible={changeDeletePopupVisibility}
            onAction={onDeleteClick}
            actionButtonTitle="Delete"
          >
            <CategoryDelete
              onDataChanged={onDataDeleteChanged}
              category={selectedCategory}
            ></CategoryDelete>
          </FormPopup>
        </div>
      </div>
    </ProtectedRoute>
  );
}
