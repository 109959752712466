import { Logs, ShortLink, ShortLinkRequest } from "../types/shortlink";
import { useApi } from "./useApi";

interface QRCodeRequest {
  origin: string;
  darkColor: string;
  lightColor: string;
}

interface ShortLinkService {
  create: (shortLink: ShortLinkRequest) => Promise<any>;
  delete: (site: string, id: string) => Promise<any>;
  update: (shortLink: ShortLinkRequest) => Promise<any>;
  getAll: () => Promise<any>;
  getQrCode: (
    url: string,
    darkColor: string,
    lightColor: string
  ) => Promise<any>;
  downloadQrCode: (
    url: string,
    darkColor: string,
    lightColor: string
  ) => Promise<any>;
  checkIfUrlExists: (url: string) => Promise<any>;
  getAllLogs: () => Promise<any>;
}

export const useShortLinkService = (): [ShortLinkService] => {
  const { requests } = useApi();

  const shortLinkService: ShortLinkService = {
    create: (shortLink: ShortLinkRequest) =>
      requests.post("/api/v1/shortlinks", shortLink),
    delete: (site: string, id: string) =>
      requests.del(`/api/v1/shortlinks/${site}/${id}`),
    update: (shortLink: ShortLinkRequest) =>
      requests.put("/api/v1/shortlinks", shortLink),
    getAll: () => requests.get<ShortLink[]>("/api/v1/shortlinks"),
    getQrCode: (url: string, darkColor: string, lightColor: string) =>
      requests.post("/qrcodes", {
        origin: url,
        darkColor: darkColor,
        lightColor: lightColor,
      }),
    downloadQrCode: (url: string, darkColor: string, lightColor: string) =>
      requests.post("/qrcodes/download", {
        origin: url,
        darkColor: darkColor,
        lightColor: lightColor,
      }),
    checkIfUrlExists: (url: string) =>
      requests.post("/api/v1/shortlinks/check-url", {
        url: url,
      }),
    getAllLogs: () => requests.get("/api/v1/shortlinks/errors"),
  };

  return [shortLinkService];
};
