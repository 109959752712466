import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";
import {
  MsalProvider,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { InteractionStatus } from "@azure/msal-browser";

import "./styles.scss";
import "./themes/theme";
import { useThemeContext, ThemeContext } from "./themes/theme";
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const isAuthenticated = useIsAuthenticated();

  const { inProgress } = useMsal();

  if (inProgress === InteractionStatus.Login) {
    return <LoadPanel visible={true} />;
  }

  if (isAuthenticated) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  const themeContext = useThemeContext();
  return (
    <Router>
      <ThemeContext.Provider value={themeContext}>
        <MsalProvider instance={msalInstance}>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </MsalProvider>
      </ThemeContext.Provider>
    </Router>
  );
}
