import "./UnauthorizedForm.scss";
import miniLogo from "../../../assets/Logo_CSL_RVB.png";

export default function UnauthorizedForm() {
  return (
    <div className="unauthorized-container">
      <div className="app-logo">
        <img src={miniLogo} alt="App Logo" />
      </div>
      <div className="access-denied-message">
        {/* <FontAwesomeIcon icon={faTimesCircle} className="access-denied-icon" /> */}
        <h1>Access Denied</h1>
        <p>You do not have permission to view this page.</p>
      </div>
    </div>
  );
}
