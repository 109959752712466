import React from "react";

import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import Validator, { RequiredRule } from "devextreme-react/validator";
import "./FormSelectBox.scss";
import SelectBox from "devextreme-react/select-box";

type SelectBoxProps = {
  value?: any;
  items?: any[];
  defaultValue?: any;
  label?: string;
  isEditing: boolean;
  valueExpr?: string | ((item: any) => string | number | boolean);
  displayExpr?: string | ((item: any) => string) | undefined;
  onValueChange: (data) => void;
  mask?: string;
  icon?: string;
};

export const FormSelectBox = ({
  value,
  label,
  items,
  defaultValue,
  isEditing,
  displayExpr,
  onValueChange,
  valueExpr,
  mask = "",
  icon,
  children,
}: React.PropsWithChildren<SelectBoxProps>) => {
  return (
    <SelectBox
      label={label}
      value={value}
      //items={items}
      dataSource={items}
      defaultValue={defaultValue}
      mask={mask}
      valueExpr={valueExpr}
      displayExpr={displayExpr}
      readOnly={isEditing}
      elementAttr={{ class: "form-editor" }}
      inputAttr={{ class: "form-editor-input" }}
      stylingMode="filled"
      onValueChange={onValueChange}
    >
      {icon && (
        <TextBoxButton
          name="icon"
          location="before"
          options={{
            icon: icon,
            elementAttr: { class: "form-editor-icon" },
          }}
        />
      )}
      <Validator>
        <RequiredRule />
        {children}
      </Validator>
    </SelectBox>
  );
};
