import {
  HomePage,
  ShortLinkPage,
  CategoryPage,
  SitePage,
  NoaccessPage,
  LogPage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/shortlinks",
    element: ShortLinkPage,
  },
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/noaccess",
    element: NoaccessPage,
  },
  {
    path: "/category",
    element: CategoryPage,
  },
  {
    path: "/site",
    element: SitePage,
  },
  {
    path: "/log",
    element: LogPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
