import React from "react";
import "./noaccess.scss";

export default () => (
  <React.Fragment>
    <div className="unauthorized-container">
      <div className="access-denied-message">
        <h1>Access Denied</h1>
        <p>You do not have permission to view this page.</p>
      </div>
    </div>
  </React.Fragment>
);
