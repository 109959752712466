import { useEffect, useState } from "react";

import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
  PatternRule,
} from "devextreme-react/form";

import { getSizeQualifier } from "../../../utils/media-query";
import { Site } from "../../../types/shortlink";
import { FormTextbox } from "../../utils/form-textbox/FormTextbox";
export const SiteForm = ({
  initData,
  visible,
  onDataChanged,
}: {
  initData: Site;
  visible: boolean;
  onDataChanged: (data) => void;
}) => {
  const [newSiteData, setNewSiteData] = useState<Site>({
    ...initData,
  });

  useEffect(() => {
    setNewSiteData({ ...initData });
  }, [initData, visible]);

  const updateField = (field: string) => (value) => {
    const newData = { ...newSiteData, ...{ [field]: value } };
    setNewSiteData(newData);
    onDataChanged(newData);
  };

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem colSpan={2}>
          <FormTextbox
            label="Name"
            value={newSiteData.name}
            isEditing={false}
            onValueChange={updateField("name")}
          />
        </FormItem>
        <FormItem colSpan={2}>
          <FormTextbox
            label="Domain"
            value={newSiteData.domain}
            isEditing={false}
            onValueChange={updateField("domain")}
          >
            <PatternRule
              message="This is not a valid url."
              pattern={
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})(\/[\w\/ \.-]*)*(\?[\w=&-]*)?(#[\w-]*)?\/?$/
              }
            ></PatternRule>
          </FormTextbox>
        </FormItem>
        <FormItem colSpan={2}>
          <FormTextbox
            label="Default Url"
            value={newSiteData.defaultUrl}
            isEditing={false}
            onValueChange={updateField("defaultUrl")}
          >
            <PatternRule
              message="This is not a valid url."
              pattern={
                /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})(\/[\w\/ \.-]*)*(\?[\w=&-]*)?(#[\w-]*)?\/?$/
              }
            ></PatternRule>
          </FormTextbox>
        </FormItem>
      </GroupItem>
    </Form>
  );
};
