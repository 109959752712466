import React from "react";
import "./home.scss";
import appLogo from "../../assets/android-chrome-512x512.png";
export default function Home() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Short Link</h2>
      <div className="home-container">
        <header className="app-header">
          <img src={appLogo} alt="Shortlink Logo" className="app-logo" />
        </header>
        <main className="main-content">
          <section className="app-info">
            <h2>Create and Manage Short URLs</h2>
            <p>
              Shortlink is an app for creating concise and manageable URLs. It
              allows you to maintain distributed URLs without the need to
              redistribute new links. Simply update the destination URL as
              needed. Organize your links into categories for easy access,
              select a domain that suits your brand, and personalize your QR
              codes with a color of your choice, available for download in SVG
              format for inclusion in documents, emails, and more.
            </p>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
}
