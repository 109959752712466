import React, { useEffect, useRef } from "react";
import "./FormQrCode.scss";
import { QRCodeSVG } from "qrcode.react";

export const FormQrCode = ({
  value,
  size,
  lightColor,
  darkColor,
  name,
}: {
  value: string;
  lightColor: string;
  darkColor: string;
  name: string;
  size: number;
}) => {
  const svgRef = useRef<any>(null);

  function downloadStringAsFile(data: string, filename: string) {
    let a = document.createElement("a");
    a.download = filename;
    a.href = data;
    a.click();
  }

  function onSVGButtonClick() {
    const node = svgRef.current;
    if (node == null) {
      return;
    }
    const svgElement = node.querySelector("svg");

    const serializer = new XMLSerializer();
    const fileURI =
      "data:image/svg+xml;charset=utf-8," +
      encodeURIComponent(
        '<?xml version="1.0" standalone="no"?>' +
          serializer.serializeToString(svgElement)
      );

    downloadStringAsFile(fileURI, name + ".svg");
  }

  return (
    <div className="form-qrcode-view">
      <div
        ref={svgRef}
        className={`form-qrcode`}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: size,
          height: size,
          maxHeight: size,
          background: lightColor,
        }}
        onClick={() => onSVGButtonClick()}
      >
        <QRCodeSVG
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={value}
          name={name}
          bgColor={lightColor}
          fgColor={darkColor}
          includeMargin={true}
        />
      </div>
    </div>
  );
};
