import axios from "axios";
//import { toast } from "react-toastify";
import { api } from "./../authConfig";

// Create a custom axios instance
const customAxios = axios.create({
  baseURL: api.baseUrl,
});

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { data, status } = error.response;

    switch (status) {
      case 400:
        if (data && data.length > 0) {
          // toast.error(data[0].message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        }
        break;
      case 401:
        // Handle unauthorised error
        break;
      case 404:
        // toast.error("communication error", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        break;
      case 500:
        // toast.error("internal error", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        break;
      default:
        // Handle other errors
        break;
    }

    return Promise.reject(error);
  }
);

export default customAxios;
