import { Routes, Route, Navigate } from "react-router-dom";
import { SingleCard } from "./layouts";
import { LoginForm } from "./components";
import UnauthorizedForm from "./components/library/unauthorized-form/UnauthorizedForm";

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route path="/unauthorized" element={<UnauthorizedForm />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="*" element={<Navigate to={"/login"} />}></Route>
    </Routes>
  );
}
