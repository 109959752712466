import React, { useCallback, useEffect, useRef, useState } from "react";
import "./shortlinks.scss";
import DataGrid, {
  Column,
  DataGridTypes,
  Export,
  FilterPanel,
  HeaderFilter,
  Item,
  Scrolling,
  Search,
  SearchPanel,
  Sorting,
  Toolbar,
  LoadPanel,
  ColumnChooser,
  Selection,
  FilterRow,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { useShortLinkService } from "../../hooks/useShortLinkService";
import {
  ShortLink,
  Site,
  SiteNone,
  defaultShortLink,
  Category,
  CategoryNone,
  RoleType,
  toRequest,
  toNewRequest,
} from "../../types/shortlink";
import { ShortLinkPreviewPanel } from "../../components/library/shortlink-preview/ShortLinkPreviewPanel";
import { FormPopup } from "../../components/utils/form-popup/FormPopup";
import Button from "devextreme-react/button";
import { ShortLinkNewForm } from "../../components/library/shortlink-new-form/ShortLinkNewForm";
import { exportDataGrid as exportDataGridToXLSX } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import { Workbook } from "exceljs";
import { useSiteService } from "../../hooks/useSiteService";
import { ShortLinkDeleteForm } from "../../components/library/shortlink-delete-form/ShortLinkDeleteForm";
import { useCategoryService } from "../../hooks/useCategoryService";
import ProtectedRoute from "../../components/utils/protected-route/ProtectedRoute";
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import { AxiosError } from "axios";

export default function ShortLinks() {
  const [categories, setCategories] = useState<Category[]>([CategoryNone]);
  const [sites, setSites] = useState<Site[]>([SiteNone]);
  const [popupNewVisible, setPopupNewVisible] = useState<boolean>(false);
  const [popupDeleteVisible, setPopupDeleteVisible] = useState<boolean>(false);
  const [isPanelOpened, setPanelOpened] = useState<boolean>(false);
  const [selectedShortLink, setSelectedShortLink] =
    useState<ShortLink>(defaultShortLink);

  const [selectedRow, setSelectedRow] = useState<number>(0);

  const [formDataDefaults, setFormDataDefaults] = useState<ShortLink>({
    ...defaultShortLink,
  });

  const gridRef = useRef<DataGrid>(null);
  const [service] = useShortLinkService();
  const [serviceCategory] = useCategoryService();
  const [siteService] = useSiteService();
  const newShortLinkData = useRef<ShortLink>(defaultShortLink);

  let deletedShortLinkData: ShortLink;

  const onLoad = async (loadOptions: LoadOptions<ShortLink>) => {
    return await service.getAll().catch((error) => {});
  };

  const onInsert = async (value: ShortLink) => {
    let shortLinkRequest = toNewRequest(value);
    return await service.create(shortLinkRequest).catch((error) => {});
  };

  const onInserted = (value: ShortLink, key: string) => {
    notify(
      {
        message: `New shortlink "${value.siteOrigin}" saved`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const onUpdate = async (key: string, value: ShortLink) => {
    let shortLinkRequest = toRequest(value);
    return service.update(shortLinkRequest);
  };

  const onUpdated = (key: string, value: ShortLink) => {
    notify(
      {
        message: `Shortlink "${value.siteOrigin}" updated`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const onRemove = async (key: string) =>
    service.delete(deletedShortLinkData.siteName, deletedShortLinkData.id);

  const onRemoved = async (key: string) => {
    notify(
      {
        message: `The shortlink "${key}" was deleted with success.`,
        position: { at: "bottom center", my: "bottom center" },
      },
      "success"
    );
    const grid = gridRef.current?.instance;
    grid?.refresh();
  };

  const [gridDataSource, setGridDataSource] = useState<
    CustomStore<ShortLink, string>
  >(
    new CustomStore({
      key: "siteOrigin",
      load: onLoad,
      update: onUpdate,
      insert: onInsert,
      remove: onRemove,
      onInserted: onInserted,
      onUpdated: onUpdated,
      onRemoved: onRemoved,
    })
  );

  useEffect(() => {
    serviceCategory
      .getAll()
      .then((response) => {
        setCategories(response);
      })
      .catch((error) => {});

    siteService
      .getAll()
      .then((response) => {
        setSites(response);
      })
      .catch((error) => {});
  }, []);

  const onDataDeleteChanged = useCallback((data: ShortLink) => {
    deletedShortLinkData = data;
  }, []);

  const onDataCreateChanged = useCallback((data: ShortLink) => {
    newShortLinkData.current = data;
  }, []);

  const changeNewPopupVisibility = useCallback((isVisble) => {
    setPopupNewVisible(isVisble);
  }, []);

  const changeDeletePopupVisibility = useCallback((isVisble) => {
    setPopupDeleteVisible(isVisble);
  }, []);

  const onFocusedRowChanged = useCallback(
    ({ row }: DataGridTypes.FocusedRowChangedEvent) => {
      if (!row) return;

      setSelectedRow(row.rowIndex);
      const shortLinkData: ShortLink = row.data as ShortLink;

      setSelectedShortLink(shortLinkData);
      setPanelOpened(true);
    },
    []
  );

  const onAddShortLinkClick = useCallback(() => {
    setPopupNewVisible(true);
  }, []);

  const onDeleteShortLinkClick = useCallback(() => {
    setPopupDeleteVisible(true);
  }, []);

  const onSaveClick = useCallback(() => {
    gridDataSource?.insert(newShortLinkData.current);
  }, [gridDataSource]);

  const onUpdateClick = useCallback(
    (data: ShortLink, category: Category) => {
      gridDataSource?.update(data.siteOrigin, data);
    },
    [gridDataSource]
  );

  const onDeleteClick = useCallback(() => {
    let dataGrid = gridRef.current?.instance;
    dataGrid?.deleteRow(selectedRow);
    dataGrid?.saveEditData();
  }, []);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance.updateDimensions();
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance.option("focusedRowIndex", -1);
  }, [isPanelOpened]);

  function renderLabel() {
    return <div className="grid-header">Short links</div>;
  }

  const onExporting = (e: DataGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("ShortLinks");

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "ShortLinks.xlsx"
        );
      });
    });
    e.cancel = true;
  };

  function checkForElement(
    selector,
    callback,
    interval = 500,
    timeout = 10000
  ) {
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const element = document.querySelector(selector);

      if (element) {
        clearInterval(intervalId);
        callback(element);
      } else if (Date.now() - startTime > timeout) {
        clearInterval(intervalId);
      }
    }, interval);
  }

  const onDataErrorOccurred = useCallback(
    (event: DataGridTypes.DataErrorOccurredEvent) => {
      let customErrorMessage: String | null = null;

      let axiosError: AxiosError = event.error as AxiosError;

      if (axiosError?.response?.data) {
        customErrorMessage =
          axiosError.response.data[0].message || customErrorMessage;
      }
      checkForElement(".dx-error-message", (element) => {
        if (customErrorMessage) element.innerHTML = customErrorMessage;
      });
    },
    []
  );

  const exportFormats = ["xlsx"];

  const comboBoxDataSource = [{ id: null, name: "All" }, ...sites];

  const allowedUsers = [RoleType.User, RoleType.Admin];
  return (
    <ProtectedRoute allowedRoles={allowedUsers}>
      <div className="view shortlink-list">
        <div className="view-wrapper view-wrapper-shortlink">
          <DataGrid
            key="siteOrigin"
            className="grid"
            ref={gridRef}
            id="shortlinksGrid"
            dataSource={gridDataSource}
            showRowLines
            showBorders
            onExporting={onExporting}
            allowColumnReordering
            focusedRowEnabled
            rowAlternationEnabled
            onDataErrorOccurred={onDataErrorOccurred}
            remoteOperations={false}
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <FilterRow visible={true} />
            <LoadPanel showPane={false} />
            <ColumnChooser enabled />
            <FilterPanel visible={true} />
            <Export enabled allowExportSelectedData formats={exportFormats} />
            <HeaderFilter visible />
            <Selection
              selectAllMode="allPages"
              showCheckBoxesMode="always"
              mode="multiple"
            />
            <Scrolling mode="virtual" />
            <Sorting mode="multiple" />
            <SearchPanel visible={true} placeholder="Search" />
            <Column dataField="id" visible={false} />
            <Column
              dataField="siteName"
              caption="Site Name"
              allowFiltering={true}
              visible={true}
              width="8%"
            >
              <HeaderFilter>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column
              dataField="category"
              caption="Category"
              width="10%"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled={true} />
              </HeaderFilter>
            </Column>
            <Column dataField="siteOrigin" visible={true} width="30%">
              <HeaderFilter>
                <Search enabled={false} />
              </HeaderFilter>
            </Column>
            <Column dataField="site" caption="Site" visible={false} />
            <Column
              dataField="lightColor"
              caption="Light Color"
              visible={false}
            />
            <Column
              dataField="darkColor"
              caption="Dark Color"
              visible={false}
            />
            <Column
              dataField="destinationUrl"
              caption="Destination Url"
              visible={true}
            />
            <Column
              dataField="views"
              caption="Views"
              width="5%"
              visible={true}
            />
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                render={renderLabel}
              ></Item>
              <Item
                location="before"
                widget="dxSelectBox"
                options={{
                  width: 200,
                  placeholder: "Filter by site",
                  dataSource: comboBoxDataSource,
                  displayExpr: "name",
                  valueExpr: "name",
                  onValueChanged: (args) => {
                    const siteName = args.value;
                    const filter =
                      siteName !== "All" ? ["siteName", "=", siteName] : null;
                    if (gridRef.current) {
                      gridRef.current?.instance.filter(filter);
                    }
                  },
                }}
              />
              <Item location="after" locateInMenu="auto">
                <Button
                  icon="plus"
                  text="Add ShortLink"
                  type="default"
                  stylingMode="contained"
                  onClick={onAddShortLinkClick}
                />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "trash",
                  type: "warning",
                  onClick: onDeleteShortLinkClick,
                }}
              />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item
                location="after"
                widget="dxTextBox"
                options={{
                  mode: "search",
                  placeholder: "Search",
                  onValueChanged: (e) => {
                    const text = e.component.option("value");
                    const grid = gridRef.current?.instance;
                    grid?.searchByText(text);
                  },
                }}
              />
              <Item
                location="after"
                widget="dxButton"
                options={{
                  icon: "refresh",
                  type: "success",
                  stylingMode: "outlined",
                  onClick: () => {
                    const grid = gridRef.current?.instance;
                    grid?.refresh();
                  },
                }}
              />
            </Toolbar>
          </DataGrid>
          <ShortLinkPreviewPanel
            shortLinkId={selectedShortLink.id}
            shortLink={selectedShortLink}
            isOpened={isPanelOpened}
            categories={categories}
            changePanelOpened={changePanelOpened}
            changePanelPinned={changePanelPinned}
            onUpdate={onUpdateClick}
          />
          <FormPopup
            title="New ShortLink"
            visible={popupNewVisible}
            setVisible={changeNewPopupVisibility}
            actionButtonTitle="Save"
            onAction={onSaveClick}
          >
            <ShortLinkNewForm
              initData={formDataDefaults}
              onDataChanged={onDataCreateChanged}
            ></ShortLinkNewForm>
          </FormPopup>
          <FormPopup
            title="Delete ShortLink"
            visible={popupDeleteVisible}
            setVisible={changeDeletePopupVisibility}
            onAction={onDeleteClick}
            actionButtonTitle="Delete"
          >
            <ShortLinkDeleteForm
              shortLink={selectedShortLink}
              onDataChanged={onDataDeleteChanged}
            ></ShortLinkDeleteForm>
          </FormPopup>
        </div>
      </div>
    </ProtectedRoute>
  );
}
