import React from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { Template } from "devextreme-react/core/template";
import type { HeaderProps } from "../../../types";
import styled from "styled-components";
import miniLogo from "../../../assets/Logo_CSL_RVB.png";

const HeaderLogo = styled.img`
  margin: 7px;
  height: 48px;
`;

export default function Header({
  menuToggleEnabled,
  title,
  toggleMenu,
}: HeaderProps) {
  return (
    <header className={"header-component-nav"}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={"before"} cssClass={"header-title"}>
          <HeaderLogo src={miniLogo} />
        </Item>
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
}
