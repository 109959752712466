import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { graphLoginRequest } from "../authConfig"; // Define your login request with the required scopes

interface UserProfile {
  name: string;
  email: string;
  pictureUrl: string | null;
}

const useProfile = () => {
  const { instance, accounts } = useMsal();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const accessTokenResponse = await instance.acquireTokenSilent({
          ...graphLoginRequest,
          account: accounts[0],
        });
        const accessToken = accessTokenResponse.accessToken;

        // Fetch the user profile information
        const userDataResponse = await fetch(
          "https://graph.microsoft.com/v1.0/me",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!userDataResponse.ok) {
          throw new Error("Could not fetch user profile");
        }

        const userData = await userDataResponse.json();

        const profilePhotoUrl = await getProfilePhoto(accessToken);

        setProfile({
          name: userData.displayName,
          email: userData.mail || userData.userPrincipalName,
          pictureUrl: profilePhotoUrl,
        });
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenPopup(graphLoginRequest as any);
        } else {
          setError(error as Error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [instance, accounts]);

  const getProfilePhoto = async (
    accessToken: string
  ): Promise<string | null> => {
    try {
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const imageBlob = await response.blob();

        // console.log(imageBlob.text());
        var url = URL.createObjectURL(imageBlob);
        return url;
      } else {
        // If the response is not okay and is an "ImageNotFound" error, return the default image path
        return null; // Replace with the path to your default imagethrow new Error("ImageNotFound");
      }
    } catch (error) {
      // If any error occurs, log it and return the default image path
      console.error("Error fetching profile photo:", error);
      return null; // Replace with the path to your default image
    }
  };

  return { profile, loading, error };
};

export default useProfile;
