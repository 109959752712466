import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import useUserRoles from "../../../hooks/useUserRoles";
import { RoleType } from "../../../types/shortlink"; // Make sure this path is correct for your project
import { useIsAuthenticated } from "@azure/msal-react";

interface ProtectedRouteProps {
  children: ReactElement; // Here we define children explicitly
  allowedRoles: RoleType[];
}

const ProtectedRoute = ({
  children,
  allowedRoles,
}: ProtectedRouteProps): ReactElement => {
  const { hasPageAccess } = useUserRoles();
  const isAuthenticated = useIsAuthenticated();

  return hasPageAccess(allowedRoles) ? (
    children
  ) : isAuthenticated ? (
    <Navigate to="/noaccess" replace />
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default ProtectedRoute;
